import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
    initialize() {
        console.log('entereing here::::');
        this._onPreConnect = this._onPreConnect.bind(this);
        this._onConnect = this._onConnect.bind(this);
    }

    connect() {
        console.log('connect');
        this.element.addEventListener('autocomplete:pre-connect', this._onPreConnect);
        this.element.addEventListener('autocomplete:connect', this._onConnect);

    }

    buildAutocompleteUrl(tomSelect, query) {
        // data-symfony--ux-autocomplete--autocomplete-url-value
        const autocompleteUrl = tomSelect.input.getAttribute('data-symfony--ux-autocomplete--autocomplete-url-value')
        const params = new URLSearchParams();
        params.append('query', query); // Original query parameter
        
        const categorySelect = document.getElementById(tomSelect.inputId + 'Category');
        if (categorySelect && categorySelect.value) {
            params.append('diagnosis_category', categorySelect.value); // Your new query parameter
        }

        return `${autocompleteUrl}?${params.toString()}`;
    }

    disconnect() {
        console.log('disconnect');
        // You should always remove listeners when the controller is disconnected to avoid side-effects
        this.element.removeEventListener('autocomplete:connect', this._onConnect);
        this.element.removeEventListener('autocomplete:pre-connect', this._onPreConnect);
        
    }

    _onPreConnect(event) {
        console.log('_onPreConnect');
        // TomSelect has not been initialized - options can be changed
        console.log(event.detail.options); // Options that will be used to initialize TomSelect
    }

    _onConnect(event) {
        console.log('_onPreConnect');
        // TomSelect has just been initialized and you can access details from the event
        const tomSelect = event.detail.tomSelect; // TomSelect instance

        tomSelect.load = (query, callback) => {
            // if (!query || !query.length) {
                
            //         tomSelect.loadCallback();
            // }
            const url = this.buildAutocompleteUrl(tomSelect, query);
            console.log('url', url);
            fetch(url)
                .then(response => response.json())
                .then(json => {
                    // const _scrollToOption = this.scrollToOption
                    
                    // dd($this->locale);
                    console.log('json', json);
                    // this.scrollToOption = () => {}
                    tomSelect.loadCallback(json.results);
                    // this.scrollToOption = _scrollToOption
                })
        }
        
    }
}