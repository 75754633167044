/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
// require('.  ./css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
import '../../bootstrap.js';
import 'tom-select/dist/css/tom-select.default.css';
import Modal from '../../components/modal/_modal.js';
import Dropdown from '../../components/dropdown/_dropdown.js';
import FormSubmission from '../../components/properties/form/_form_submission.js';

$(function() {
    // Disable all loading page css transitions
    $(".preload").removeClass("preload");

    // Set the size of .inpuSearch components based on placeholder size
    var input = document.querySelectorAll('input.InputSearch:not(.InputSearch--noResize)');
    for (var i = 0; i < input.length; i++){
        input[i].setAttribute('size', input[i].getAttribute('placeholder').length * 0.6 + 4);
    }

    // Init the dropdowns
    Dropdown.init();

    $(".Cost").on("focus", function (event) {
        onInputFocus(event);
        // this.setSelectionRange(0, this.value.length)
    });

    function onInputFocus(event) {
        const target = event.currentTarget;
        target.type = 'text';
        target.setSelectionRange(0, target.value.length);
        target.type = 'number';
    }

    // Redirects user to the login page, if receive a 401 error from ajax response
    $(document).ajaxError(function (event, jqXHR) {
        if (401 === jqXHR.status) {
            setTimeout(function () { $(location).attr('href', Routing.generate('app_login'))}, 2000);
        }
    });


    // Contacts the administrator through a contact form
    $('.Footer__link[data-action=contact]').on('click', function () {
        $.ajax({
            method: "POST",
            dataType : 'html',
            url: Routing.generate($(this).data('path')),
            success: function (response) {
                $('.Contact__ctn').html(response);
                Modal.init();
                Modal.show('.Contact__ctn .ModalWrapper--hidden');
                FormSubmission.init($('.Contact__ctn *[type=\'submit\']'));
            }
        });
    });

});
